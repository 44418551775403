
import { Component, Vue } from "vue-property-decorator";
import Licitaciones from "@/components/Licitaciones/Licitaciones.vue";

@Component({
  components: {
    Licitaciones
  }
})
export default class LicitacionesView extends Vue {}
