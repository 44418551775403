
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Licitaciones extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;
  private dataLicitaciones: any = "";
  private currentPage = 1;
  private total = 0;
  private paginaCargada = false;
  private nombreWeb: any = "";

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 20)) {
      this.getLicitaciones();
    }
  }

  private mounted() {
    this.getLicitaciones();
  }

  private getLicitaciones() {
    this.$axios
      .get("licitaciones?pagina=" + this.currentPage)
      .then((res: any) => {
        this.dataLicitaciones = res.data;
        this.total = res.data.total;
        this.paginaCargada = true;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private irLicitacion(nombreWeb: any) {
    if (this.$router.currentRoute.name == "Licitacion") {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Licitacion",
          params: { name: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
